<template>
  <div class="min-h-screen">
    <UINav />
    <slot />
  </div>
  <UIFooter />
</template>

<script setup lang="ts"></script>

<style scoped></style>
